<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            ProfitPanda
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Регистрация
        </b-card-title>
        <b-card-text class="mb-2">
          Введите данные для создания учетной записи
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="Введите ваш email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Пароль"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Пароль"
                rules="required|confirmed:confirm"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label="Повторите пароль"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                vid="confirm"
                name="Повтор пароля"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="passwordConfirm"
                    v-model="passwordConfirm"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- checkbox -->
            <b-form-group v-if="false">
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                Я согласен с
                <b-link>с политикой конфиденциальности</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              <b-spinner
                v-show="isSignIn"
                small
              />
              <span v-show="!isSignIn">Начать</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Уже есть аккаунт? </span>
          <b-link :to="{name:'auth-login'}">
            <span>Войти</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- Register -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BSpinner, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { keysToCamel } from '@/libs/helpers'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BSpinner,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',
      passwordConfirm: '',
      // validation rules
      required,
      email,
      isSignIn: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        // eslint-disable-next-line no-empty
        if (success) {
          this.isSignIn = true
          useJwt.register({
            email: this.regEmail,
            password: this.password,
            confirmPassword: this.passwordConfirm,
          }).then(regData => {
            const authParams = new URLSearchParams({
              username: regData.data.email,
              password: regData.data.password,
              grant_type: 'password',
              scope: 'openid offline_access',
            })

            useJwt.login(authParams, {
              headers:
                  {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
            }).then(response => {
              const userData = keysToCamel(response.data)
              const userDataDemo = {
                userRole: 'admin',
                ability: [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ],
              }
              useJwt.setToken(userData.accessToken)
              useJwt.setRefreshToken(userData.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userDataDemo))
              this.$ability.update(userDataDemo.ability)
              this.$router.replace(getHomeRouteForLoggedInUser(userDataDemo.userRole))
                .then(() => {
                  this.isSignIn = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Неподтвержденная почта',
                      icon: 'LogInIcon',
                      variant: 'danger',
                      text: 'Подтвердите аккаунт на почте',
                    },
                  }, {
                    position: 'top-right',
                  })
                })
            })
              .catch(error => {
                this.isSignIn = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ошибка регистрации',
                    icon: 'LogInIcon',
                    variant: 'danger',
                    text: error.response.data,
                  },
                }, {
                  position: 'top-center',
                })
                this.$refs.loginForm.setErrors(error.response.data.error)
              })
          }).catch(error => {
            this.isSignIn = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка регистрации',
                icon: 'LogInIcon',
                variant: 'danger',
                text: error.response.data,
              },
            }, {
              position: 'top-center',
            })
            this.$refs.loginForm.setErrors(error.response.data.error)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
